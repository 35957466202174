import chair from './chair.jpg'
import cobra from './cobra.jpg'
import dog from './dog.jpg'
import tree from './tree.jpg'
import warrior from './warrior.jpg'
import traingle from './traingle.jpg'
import shoulderstand from './shoulderstand.jpg'

export const poseImages = {
    Tree: tree,
    Cobra: cobra,
    Dog: dog,
    Warrior: warrior,
    Chair: chair,
    Traingle: traingle,
    Shoulderstand: shoulderstand
} 



 